@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box; 
}
*::selection {
  background-color: white;
  color: rgb(255, 215, 0); /* Gold color */
}

*::-webkit-scrollbar {
  display: none;
  background-color: #040404;
  width: 0.4vw;
  z-index: 99;
}
*::-webkit-scrollbar-thumb {
  background-color: rgb(255, 165, 0); /* Gold variation */
  border-radius: 2vw; 
}
@media (max-width: 400px) {
  *::-webkit-scrollbar-thumb {
    display: none;
  }
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.stroke {
  max-height: 380px;
  background: #d6d6d6;
}
.animate {
  animation: shimmer 3s infinite;
  background: linear-gradient(to right, rgba(255, 255, 255, 0.1), rgba(255, 215, 0, 0.3), rgba(255, 255, 255, 0.1)); /* Gold shimmer */
  background-size: 1000px 100%;
}
.btn.btn-bg-slide::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(to bottom right, rgba(255, 223, 0, 0.7), rgba(255, 185, 15, 0.8), rgba(184, 134, 11, 0.7)); /* Gold gradients */
  z-index: -1;
  transform: scaleX(0);
  transform-origin: left;
}
.btn.btn-bg-slide:hover::before,
.btn.btn-bg-slide:focus::before {
  transform: scaleX(1);
  transition: transform 280ms ease-in-out;
}
.btn.btn-bg-slide {
  z-index: 1;
  background-color: rgba(184, 134, 11, 0.75); /* Gold base */
}

.foot {
  box-shadow: 0px -4px 40px rgba(26, 26, 26, 0.712);
}

.lii {
  transition-duration: 0.4s;
  transition-timing-function: ease-in-out;
}

@keyframes shimmer {
  from {
    background-position: -1000px 0;
  }
  to {
    background-position: 1000px 0;
  }
}
.hand {
  animation-name: hand;
  animation-duration: 10s; /* Adjust duration as needed */
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes hand {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.spanCSS {
  transition: 0.1s;
  transition-timing-function: ease-in-out;
}

.inputCSS:focus + .spanCSS,
.inputCSS:valid + .spanCSS {
  top: -15px;
  font-size: 14px;  
}
